.nx_table_wrapper {
  height: 100% !important;
  display: grid;
  grid-template-rows: auto;
  position: relative;

  .MuiTable-root:has(.MuiTableFooter-root) {
    position: sticky;
    bottom: -10px;
    right: 0;
    overflow: hidden;
    background-color: white;
    z-index: 100 !important;
  }

  @media screen and (max-width: 1400px) {
    div:has(.MuiTable-root) {
      max-height: 400px !important;
      overflow: auto !important;
    }
  }

  div:has(.MuiTable-root) {
    max-height: 650px;
    overflow: auto !important;
  }

  .MuiToolbar-root {
    display: none;
  }

  .MuiPaper-elevation {
    height: 90% !important;
  }

  .MuiTablePagination-toolbar.MuiToolbar-root {
    display: flex;
  }

  &.MuiPaper-root {
    box-shadow: none;
  }

  .MuiTableRow-head {
    .MuiTableCell-root {
      padding: 0;

      .MuiButtonBase-root {
        width: 100%;
        & > div {
          width: 100%;
        }
        &.MuiIconButton-root {
          width: auto;
        }
      }
    }
  }
  .MuiPaper-elevation {
    display: none;
  }

  tr {
    height: 52px !important;
  }

  td {
    font-size: 0.875rem !important;
  }

  td > div > div {
    font-size: 0.875rem !important;
  }

  tr td,
  th {
    height: 52px !important;
    padding: 0 5px !important;
    font-size: 0.875rem !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    span {
      button {
        div {
          div {
            max-width: 285px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  th > div {
    height: 52px !important;
    font-size: 0.875rem !important;
  }

  .MuiTableRow-root {
    position: relative;
  }

  .MuiTableRow-root > th {
    position: relative;
  }

  .expandable_rows .MuiCheckbox-root {
    display: block;
  }

  .custom_checkbox {
    display: block;
    position: absolute;
    left: 35px;
    bottom: 0px;
  }

  .MuiTableBody-root > tr > td {
    overflow: auto;
  }

  tbody > tr > td:first-child {
    width: 20px;
    position: relative;
    padding: 0 10px !important;
  }

  thead > tr > th:first-child {
    padding: 0 10px !important;
    visibility: hidden;
  }

  tbody > tr > td:nth-child(3) {
    width: 20px;
  }

  .MuiTableRow-head th:nth-child(3) {
    min-width: 200px;
  }

  .expandable_rows .MuiCheckbox-root input,
  .expandable_rows .MuiCheckbox-root {
    width: 24px;
    height: 24px;
  }
}
