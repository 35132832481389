.menu_item {
  position: relative;
  display: block;
  width: 280px;
  height: 57px;
  color: #a5a7af !important;
  font-size: 16px;
}

.menu_item_active {
  border-radius: 10px !important;
  background-color: rgba(40, 40, 40, 0.35) !important;
  color: white !important;
}

.css-1domaf0 {
  width: 280px !important;
}

@media only screen and (max-width: 820px) {
  .nx_menu {
    width: 100vw !important;
    z-index: 999;
    background-color: rgb(30, 30, 30) !important;
    transition: width 0.9s ease 0s !important;
  }

  .nx_content {
    grid-template-rows: 0 auto !important;
  }

  .nx_hamburger {
    top: 2px !important;
    left: 5px !important;
  }
}
