body {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Calibri;
  font-size: 16px;
  font-weight: normal;
}

.MuiDataGrid-row {
  cursor: pointer;
}

html,
#root {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Poppins !important;
  overflow: hidden;

  * {
    font-family: Poppins !important;
    scrollbar-color: #dee2e7 #fff;
    scrollbar-width: thin !important;

    ::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border: 4.5px solid transparent;
      border-left-width: 2px;
      border-right-width: 2px;
      border-radius: 2px;
      background-clip: content-box;
    }

    ::-webkit-scrollbar {
      background-color: #fff;
      width: 11px;
      overflow: visible;
      height: 15px;
    }
  }

  .uf_loading {
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }

  canvas {
    max-width: 1599px;
    max-height: 700px;
  }

  .nx_profile_info {
    display: none;
  }

  .nx_refresh {
    display: none;
  }

  @media only screen and (max-width: 820px) {
    body {
      background-color: white;
      .uf_login {
        background-image: none !important;

        .uf_login_card {
          width: 100%;

          .uf_login_login {
            background-color: white;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .expanses_category_filter {
        margin-top: 20px;
      }

      .nx_profile_info {
        display: flex !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        color: rgba(34, 42, 96, 0.9);
        font-size: 13px;
      }
      .nx_refresh {
        position: absolute;
        top: 15px;
        right: 15px;
        display: block !important;
      }
    }
  }
}
