.grid_container {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
}

.border_header_left {
  text-align: center;
  border-bottom: 2px solid transparent;
}

.border_header_right {
  text-align: center;
  border-bottom: 2px solid transparent;
}
